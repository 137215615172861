@import "react-alice-carousel/lib/alice-carousel.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  /* Light theme */
  --primary-color-l: black;
  --background-color-l: white;
  --text-color-l: #333333;

  /* Dark theme */
  --primary-color-d: black;
  --background-color-d: white;
  --text-color-d: #ffffff;
}

#root{
  display: flex;
  flex-direction: column;
  width: 100%;
}

@font-face {
  font-family: 'Nunito';
  src: url('/src/fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito Black';
  src: url('/src/fonts/Nunito-Black.ttf') format('truetype');
}

body{
  display: flex;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}

body.light {
  --theme-primary-color: var(--primary-color-l);
  --theme-background-color: var(--background-color-l);
  --theme-text-color: var(--text-color-l);
}

body.dark {
  --theme-primary-color: var(--primary-color-d);
  --theme-background-color: var(--background-color-d);
  --theme-text-color: var(--text-color-d);
}

/* Example usage */
.container {
  background-color: var(--theme-background-color);
  color: var(--theme-text-color);
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/*.container-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-content: center;
}*/



.sub-container {
  background-color: var(--theme-background-color);
  color: var(--theme-text-color);
  display: flex;
  flex-direction: column;
  width: 900px;
  align-items: center;
  align-content: center;
  padding:12px 12px;
  margin-bottom:30px;
  margin-top: 20px;
}

@media (max-width: 900px) {
  .sub-container {
    width: 84%;
  }
}

.img{
  max-width: 100%;
  height: auto;
}

.sub-container-no-top {
  background-color: var(--theme-background-color);
  color: var(--theme-text-color);
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 650px;
}

@media (max-width: 900px) {
  .sub-container-no-top {
    width: 100%;
  }
}

.button {
  background-color: var(--theme-primary-color);
  color: var(--theme-text-color);
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 40px;
}

.button-container-noborder {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}

.b {
  margin-top: 30px;
}
.c {
  margin-top: 20px;
}

.custom-divider {
  border-bottom: 2px solid grey !important;
  border-radius: 10px !important;
}

.button-style {
  width: 100%;
  padding: 20px 28px !important;;
  color: white;
  box-shadow: 0px 5px 10px 0px rgba(72, 8, 255, 0.25) !important;
  background: linear-gradient(
          130deg,
          #FF0844 0%,
          #FF0844 30%,
          #4808FF 85%,
          #4808FF 100%
  );
}

.button-style-sub {
  padding: 20px 28px !important;;
  color: white;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25) !important;
  background: #FA3464 !important;
}

.button-style-noshadow {
  border: 1px solid black;
  width: 100%;
  padding: 20px 28px !important;;
  color: white;
  background: linear-gradient(
          130deg,
          #FF0844 0%,
          #FF0844 30%,
          #4808FF 85%,
          #4808FF 100%
  );
}
.button-style-agenda {
  width: 100%;
  box-shadow: 0px 5px 10px 0px rgba(72, 8, 255, 0.25) !important;
  padding: 20px 28px !important;;
  color: white;
  background: linear-gradient(90deg, #FA3464 0%, #9747FF 100%);
}

.blue-button {
  width: 100%;
  padding: 20px 28px !important;;
  box-shadow: 0px 5px 10px 0px rgba(72, 8, 255, 0.25) !important;
  color: white;
  background: #4808FF !important;
}

.blue-button .MuiTypography-root {
  font-size: 18px;
}

.white-button {
  width: 100%;
  padding: 20px 30px !important;;
  box-shadow: 0px 5px 10px 0px rgba(72, 8, 255, 0.25) !important;
  color: black;
  background: white !important;
}

.white-button .MuiTypography-root {
  font-size: 18px;
}

.button-style-agenda .MuiTypography-root {
  font-size: 18px;
}

.button-style-sub .MuiTypography-root {
  font-size: 18px;
}

.button-style .MuiTypography-root {
  font-size: 18px;
}


.grey-locked-button{
  width: 100%;
  padding: 20px 28px !important;;
  box-shadow: 0px 5px 10px 0px rgba(72, 8, 255, 0.25) !important;
  color: white;
  background: #8A9699 !important;
}

.grey-locked-button .MuiTypography-root {
  font-size: 18px;
}

.button-style-nogr {
  width: 100%;
  padding: 20px 28px !important;
  background: white;
  color: black;
}

.button-style-nogr .MuiTypography-root {
  font-weight: 400;
  font-size: 18px;
}

.button-style-noborders-lil.MuiButtonBase-root {
  padding: 10px 25px;
  color: black;
  line-height: 16px;
}

.button-style-noborders-lil.MuiButtonBase-root .MuiTypography-root {
  line-height: 16px;
}


.button-style-borders-lil-white.MuiButtonBase-root {
  padding: 10px 25px;
  background-color: white;
  border: 1px solid black;
  font-size: 18px;
}

.button-style-noborders-lil.MuiButtonBase-root > .MuiTypography-root {
  font-weight: 400;
}

.forgotpassword.MuiButtonBase-root > .MuiTypography-root {
  font-weight: 400 !important;
  text-decoration: underline !important;
}

.button-style-noborders-mdp.MuiButtonBase-root {
  width: 100%;
  padding: 20px 4px;
  border: none !important;
  color: black;
}
.button-style-noborders-home.MuiButtonBase-root {
  width: 100%;
  padding: 20px 28px;
  border: none !important;
  color: black;
}

.button-style-noborders.MuiButtonBase-root {
  width: 100%;
  padding: 20px 28px;
  border: none !important;
  color: black;
}
.button-style-noborders-share.MuiButtonBase-root {
  width: 100%;
  padding: 5px;
  border: none !important;
  color: black;
}

.button-style-borders-share a {
  line-height: 2px;
}

.iconbutton-noborders.MuiButtonBase-root {
  border: none !important;
}

.whitePaper .MuiPaper-root {
  background: white !important;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container,
.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 90%;
}

.button-style-borders-icon.MuiButtonBase-root {
  border: 1px solid black !important;
  color: black;
}

.button-style-noborders-socialicon.MuiButtonBase-root {
  background: linear-gradient(180deg, #FF0844 0%, #4808FF 100%);
  color: white;
  box-shadow: 0px 4px 15px 0px rgba(72, 8, 255, 0.25);
}

.button-style-noborders-socialicon-white.MuiButtonBase-root {
  background: white;
  color: black;
  box-shadow: 0px 4px 15px 0px rgba(72, 8, 255, 0.25);
}

.button-style-borders.MuiButtonBase-root {
  width: 100%;
  padding: 20px 28px;
  border: 1px solid black !important;
  color: black;
}

.button-style-borders-share.MuiButtonBase-root {
  width: 100%;
  border: 1px solid white !important;
}

.MuiInputBase-input  {
  background: #F1F5F8 !important;
  border-radius: 12px;
  border : none !important;
}

.bar-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-content: center;
  align-items: center;
  margin-top: 30px;
}

.field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 50px;
}

.field-container-b {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
  align-items: center;
  margin-top: 50px;
}

.field-style {
  width: 100%;
  --webkit-transform: translate3d(0,0,0) !important;
}

.title-field-container {
  display: flex;
  align-self: start;
  margin-bottom: 10px;
}

/*.MuiButtonBase-root {
  border: 1px solid black !important;
}*/


/*.footer-container {
  display:flex;
  margin-top: 40px !important;
  width:100%;
}*/

.footer-container {
  display:flex;
  margin-top: auto;
  width:100%;
}

.container-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-content: center;
}

/*.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}*/
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
}

.text-field-style{
  font-weight:700;
  /*letter-spacing:1px;*/
  color:black;
  font-size:16px;
}

.text-field-light-style{
  font-weight:550;
  color:black;
  font-size:16px;
}

.title-container{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 14px;
}

.title-container-nologo{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 14px;
}


.title-style{
  font-weight: 900;
  font-size: 24px;
  color: black;
  font-family: 'Nunito Black';
}

.title-style-presta{
  font-weight: 900;
  font-size: 20px;
  color: black;
}

.button-autocomplete .MuiButtonBase-root {
  border: none !important;
}

.transparentBackground {
  background-color: transparent !important;
}

.custompaper .MuiPaper-root {
  border-radius: 15px !important;
  box-shadow: none !important;
}

.custom-background {
  background: linear-gradient(
          130deg,
          #ff0844 0%,
          #ff0844 30%,
          #4808ff 85%,
          #4808ff 100%
  );
}

.snackbar-bottom-center {
  width: 100%;
  max-width: 100%;
}

.MuiInputBase-inputMultiline{
  border-radius: 0 !important;
}

.multiline-span {
  white-space: pre-line; /* or use "pre-line" for collapsing consecutive spaces */
}

/* The base style for the custom button */
.custom-button {
  background-color: #DAE0E5; /* Light grey by default */
  color: #000; /* Black by default */
  border: none;
  padding: 10px;
  border-radius: 50%; /* To make it a rounded button */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 40px;
  height: 40px;
}

/* The style for the button when it's selected */
.custom-button.selected {
  background-color: #000; /* Black when selected */
  color: #fff; /* White when selected */
}

/* Style for the letter inside the button */
.letter {
  font-size: 20px;
  font-weight: 700;
  transition: color 0.3s ease;
}


.image-wrapper-home {
  margin-top: 10px;
  position: relative;
  padding-bottom: 133%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.image-container-home {
  flex-basis: 48%;
  margin-bottom: 1rem;
  position: relative;
  border-radius: 15px;
  cursor: pointer;
}

.image-text-home {
  padding: 10px;
  color: black;
  font-size: 16px;
}

.imstyle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.image-gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width:100%;
}

.image-wrapper {
  flex-basis: 48%;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 66.666%; /* 3:2 aspect ratio (2 / 3 * 100%) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.image-wrapper > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.image-wrapper-q {
  flex-basis: 80%;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 50%; /* 3:2 aspect ratio (2 / 3 * 100%) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.image-wrapper-q img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-wrapper-pop {
  flex-basis: 48%;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 133.2%; /* 3:2 aspect ratio (2 / 3 * 100%) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.image-wrapper-pop > img:first-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.eye-button{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}

.alice-carousel {
  text-align: center;
  overflow: visible;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active{
  background-color: #1F1F1F !important;
}

.alice-carousel__dots{
  margin-top: 6px !important;
}

.alice-carousel__stage-item{
  padding: 10px !important;
}

.alice-carousel > div > div > ul > li > div {
  position: relative;
  width: 100%;
  padding-top: 133.33%; /* 4:3 aspect ratio (3/4 * 100%) */
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.alice-carousel > div > div > ul > li > div > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio while covering the container */
  border-radius: 15px;
}

.date-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 24px;
}

.date-card {
  flex-basis: 48%;
  box-shadow: 0px 4px 15px 0px rgba(218, 224, 229, 0.90);
  border-radius: 15px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}

.date-card-nopresta {
  width: 100%;
  box-shadow: 0px 4px 15px 0px rgba(218, 224, 229, 0.90);
  border-radius: 15px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}

.rdv-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 4px 15px 0px rgba(218, 224, 229, 0.90);
  border-radius: 15px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.loaded.fade-in {
  opacity: 1;
}

.logo-size{
  width:30px;
  height:30px;
}

.custom{
  right: 8px;
  top: 7px;
  position: absolute;
  z-index: 1;
  color: #F2F2F2;
  width: 20px;
  height: 20px;
  overflow: visible;
}

.label-style {
  display: flex;
  align-items: center;
}

.bold-part {
  font-weight: 700;
}

.importantwidthheight{
  width: 24px !important;
  height: 24px !important;
}

.importantwidthheightbis{
  width: 90px !important;
  height: 90px !important;
}

.MuiAutocomplete-noOptions{
  visibility: hidden;
  position: absolute;
}

.aster{
  font-size: 12px;
}

.MuiButton-root.MuiButtonBase-root.stickyButton {
  z-index: 1000;
  position: relative;
}

.react-tel-input .form-control{
  background: #F1F5F8 !important;
  border: none !important;
  border-radius: 15px !important;
  width: 100% !important;
}

.react-tel-input .form-control :focus {
  background: #F1F5F8 !important;
  border: none !important;
  border-radius: 15px !important;
  width: 100% !important;
}

.react-tel-input .form-control:focus{
  border-color: transparent !important;
  box-shadow: none !important;
}